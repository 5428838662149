
.App {
  text-align: center;
}

.imageContainer{
  height: 150px;
  width: 150;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerText{
  text-decoration: none;
  color: black;
  transition: 0.2s;;
}

.footerText:hover{
  transition: 0.3 s;
  transform:scale(1.1);
  color:black;  
}
.buttonClass{
  margin-top: 1.5vh;
  margin-bottom: 2vh;
  box-shadow: 1px 1px 10px black;
  transition: 0.2s;
  
}
.cardImage{
  height: 120px;
  width: 120px;
}

.Col2, .Col1, .Col3{
  margin-bottom: 15px;
  padding: 30px;
}

.cardImage2{
  height: 130px;
  width: 130px;
}
.cardImage3{
  height: 80px;
  width: 130px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.rightmargin{
  margin-right: 50px;
}

.headerCSS{

  background-color: rgba(255, 160, 145, 30%);
  box-shadow: 1px 0px 10px gray;
}


.navLink{
  margin: 0px 20px;
  transition: .8s;
}

.coverCarousel{
  height:50vh;
  width:100%;
  background-color:black;
  position:absolute;
  top:0px;
  opacity:60%;
}
  .navLink:hover {
  transition: .3s;
  background-color: #FFE6D8;
  border-radius: 5rem;
  }
.carddimensions{
  margin-top: 1.5vh;
  background-color: white;

}

@media screen and (max-width: 768px){
  .sampleRow{
    flex-direction: column-reverse;
  }
}

.footer{
padding: 3vh 3vh;

  background-color: rgba(255, 160, 145, 30%);


  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media screen and (max-width: 768px){
  .footerContain{
    padding-top: 2vh;   
    display: flex;
    flex-direction: column;
    text-align: center; 

  }
}

@media screen and (min-width: 768px){
  .footerContain{
    padding-top: 2vh;
    display: flex;
    flex-direction: row;
    text-align: left;
      justify-content: space-between;
  }
}


@media screen and (max-width: 576px){
  .footerContain{
    padding-top: 2vh;   
    display: flex;
    flex-direction: column;
    text-align: center; 

  }
}


  .footerLogo{
    display: flex;
    text-align: center;
    justify-content: "center";
    align-items: "center";
    width:100%;
  }





  .footerRight{
 
    display: flex;
    text-align: center;
    justify-content: "center";
    align-items: "center";
  }


@media screen and (min-width: 768px){
  .captionCarousel{
    position: absolute;
    top: 10vh;
  }
}
.normal{
  visibility: hidden;
}

.outOfFlow{
  position: fixed;
  visibility: hidden;
}
.success{
  position: relative;
  font-family: "Poppins";
  font-size: 1.5rem;
  visibility: visible;
  color: #2ecc71;
  margin: 2vh 0vh;
}
.error{
  position: relative;
  font-family: "Poppins";
  font-size: 1.5rem;
  visibility: visible;
  color: #e74c3c;
}
.sampleMargin{

  margin-top: 10vh;
  margin-bottom: 8vh;
}
.sampleContainerMid{
  margin-top: 2vh;
  margin-bottom: 7vh;
}
.sampleImage{
  width: 100%;
  object-fit: contain;
  transition: 0.4s;
 
}


.sampleImageEquipment{
  margin-top: 3vh;
  margin-bottom: 3vh;
  height: 40vh;
  object-fit: cover;
  transition:0.4s;
}
.sampleImageEquipment:hover{
  transition:0.2s;
  transform: scale(1.2);

}
h1, h2, h3, h4, h5, h6{
  font-family: "Poppins", Arial, Helvetica, sans-serif;
}
.sampleImage:hover{
 
  transition: 0.2s;
  transform: scale(1.1);
}
.sampleImageLarge{
  width: 100%;
  object-fit: contain;
  transition: 0.4s;
  overflow: hidden;
}

.buttonClass:hover{
  transition: 0.2s;
 
  transform:scale(1.1);
}

.sampleContainerLarge{
  margin-top: 0vh;
  margin-bottom: 7vh;
  
}


.imageCarousel{
  height: 50vh;
  width: 100%;
  object-fit: cover;

}
.hr{
  width: 100%;
  height: 10px;
}
.formContainer{
  margin: 5vh 0vh 0vh 10%;

  width: 80%;

}
.rowdimensions{
  width: 100%;
  text-align: center;
  padding: 3vh 3vh;
}


.headerImage{
  height:35vh;
  width: 100%;
  object-fit: contain;

}

.truplanlogo{
  height: 15vh;
  width: 20vh;
  object-fit: contain;
  margin-left: 5vh;
  transition: 0.2s;
}

.truplanlogo:hover{
  transform: scale(1.1);
}


.headingCarousel{
  font-family: "Poppins";
  font-size: 4vh;
  font-weight: bold;
  text-shadow: 2px 2px 5px black;
  margin-bottom: 5vh;
}

.paraCarousel{
  font-size: 2.5vh;
  text-shadow: 1px 1px 5px black;
}

